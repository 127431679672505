import { useMemo, useState, useEffect } from "react";
import Pagination from "../../../components/Pagination/Pagination";
import ErrorMessage from "../../../components/ErrorMessage/ErrorMessage";
import { SignalReportModal } from "../../../components";
import {
  convertISOToReadableDate,
  getAmbientScreeningSubmissionById,
} from "../../../helpers";
import { ReportActionButtons, SECTION_KEYS } from "../components/ReportActionButtons";
import useScreenedManuscripts from "../hooks/useScreenedManuscripts";
import { useAppContext } from "../../../components/AppContext";
import { baseColumns as tableColumns } from "../columns";
import { DataTable, useLoader } from "stm-frontend-library";

export const UnderReviewManuscriptsTab = ({ isActiveTab }) => {
  const [selectedItem, setSelectedItem] = useState({
    index: null,
    visibleSection: SECTION_KEYS.ALL,
  });
  const [fetchedData, setFetchedData] = useState(null);
  const { searchInput } = useAppContext();

  const { showLoader, hideLoader, isLoading } = useLoader();

  const {
    data,
    error,
    pageCount,
    currentPage,
    setCurrentPage,
    searchTerm,
    setSearchTerm,
  } = useScreenedManuscripts({ tab: "under_review_manuscripts", isActiveTab });

  const handleIconClick = (index, buttonKey) => {
    setSelectedItem({ index, visibleSection: buttonKey });
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const fetchAmbientScreeningDataIfNeeded = async (index) => {
    const { persistent_id, signals } = data[index];
    if (persistent_id && signals?.some((s) => s !== 'duplicateSubmission')) {
      try {
        showLoader();
        const { data: fetchedData } = await getAmbientScreeningSubmissionById(persistent_id);
        setFetchedData(fetchedData);
      } finally {
        hideLoader();
      }
    }
  };

  useEffect(() => {
    if (selectedItem.index !== null) {
      fetchAmbientScreeningDataIfNeeded(selectedItem.index);
    } else {
      setFetchedData(null);
    }
  }, [selectedItem, data]);

  const resetModalState = () => {
    setFetchedData(null);
    setSelectedItem({
      index: null,
      visibleSection: SECTION_KEYS.ALL,
    });
  };

  const dataWithActions = useMemo(() =>
    data?.map((manuscript, i) => ({
      ...manuscript,
      timestamp: convertISOToReadableDate(manuscript.timestamp, "DD MMM YYYY"),
      signals: (
        <ReportActionButtons
          signals={manuscript.signals}
          onIconClick={(buttonKey) => handleIconClick(i, buttonKey)}
        />
      ),
    })) || [],
    [data]
  );

  useEffect(() => {
    const trimmedValue = searchInput.trim();
    if ((!searchTerm && !trimmedValue) || (trimmedValue === searchTerm)) return;
    setSearchTerm(trimmedValue);
  }, [searchInput, searchTerm, setSearchTerm]);

  return (
    <>
      {selectedItem.index !== null && (
        <SignalReportModal
          dupSubData={data[selectedItem.index]}
          contentData={fetchedData}
          visibleSection={selectedItem.visibleSection}
          onClose={resetModalState}
        />
      )}
      {error && <ErrorMessage />}
      {!error && !isLoading && (
        <>
          <DataTable
            columns={tableColumns}
            rows={dataWithActions}
          />
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={pageCount}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  );
};

export default UnderReviewManuscriptsTab;