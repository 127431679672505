import { useEffect, useState } from "react";
import { convertISOToReadableDate, getPubpeerList } from "../../helpers";
import Pagination from "../../components/Pagination/Pagination";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import { columns } from "./columns";
import { MDBContainer } from "mdb-react-ui-kit";
import { ITEMS_PER_PAGE } from "../../constants";
import { CountCard } from "../../components";
import { PubpeerReviewModal } from "./components/PubpeerReviewModal";
import { DataTable, useLoader } from "stm-frontend-library";

const Pubpeer = () => {
  const [error, setError] = useState(false);

  const [data, setData] = useState([]);
  const [totalHits, setTotalHits] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedDataRowIndex, setSelectedDataRowIndex] = useState(null);

  const { isLoading, hideLoader, showLoader } = useLoader();

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
  };

  const handleRowClick = (index) => {
    setSelectedDataRowIndex(index);
  };

  const handleCloseModal = () => setSelectedDataRowIndex(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoader();
        const { data: { manuscripts = [], total = 0 } = {} } =
          await getPubpeerList();
        setData(manuscripts);
        setTotalHits(total);
        setPageCount(Math.ceil(total / ITEMS_PER_PAGE));
      } catch (error) {
        setError(true);
      } finally {
        hideLoader();
      }
    };

    fetchData();
  }, []);

  const dataWithActions = data?.map((element, index) => ({
    ...element,
    timestamp: convertISOToReadableDate(element.timestamp, "DD MMM YYYY"),
    status: element?.screening_outcomes?.[0].type,
    clickEvent: () => handleRowClick(index),
    hits:
      element?.screening_outcomes?.find((x) => x.type === "feetOfClayDetector")
        ?.items?.[0]?.details?.length ?? 0,
  }));

  return (
    <MDBContainer className="my-5">
      {selectedDataRowIndex !== null && (
        <PubpeerReviewModal
          contentData={data[selectedDataRowIndex]}
          onClose={handleCloseModal}
        />
      )}
      {error && <ErrorMessage />}
      {!error && !isLoading && (
        <>
          <div className="d-flex justify-content-center m-5">
            <CountCard
              count={totalHits}
              title={"Hits"}
              subtitle={"Total hits"}
            />
          </div>
          <DataTable columns={columns} rows={dataWithActions} />
          <Pagination
            handlePageClick={handlePageClick}
            pageCount={pageCount}
            currentPage={currentPage}
          />
        </>
      )}
    </MDBContainer>
  );
};

export default Pubpeer;
